import React, {useContext, useEffect, useRef, useState} from "react"

import s from  "./TransportTarif.module.scss"
import LoadingSpinner from "../../components/loading/LoadingSpinner"
import {DataContext} from "../../context/DataContext";
import isNumeric from "../../components/functions/isNumeric";
import ModalFiles from "../../components/modalwin/ModalFiles";
import {Context} from "../../index";
import {useMessage} from "../../hooks/message.hook";
import {useNavigate} from "react-router-dom";
import ReferenceService from "../../services/ReferenceService";
import * as XLSX from "xlsx";
import CmsSelect from "../../components/inputs/CmsSelect";

function TransportTarif(){
    const [loading,setLoading] = useState(false)

    const {optionsMonth3,optionsYear2} = useContext(DataContext)

    const [selectedYear,setSelectedYear] = useState(optionsYear2[1])
    const [selectedMonth,setSelectedMonth] = useState(optionsMonth3[new Date().getMonth()])

    const [ogmPrices,setOgmPrices] = useState([])
    const [ogmPricesOld,setOgmPricesOld] = useState([])
    const [activeDelete,setActiveDelete] = useState(false)
    const [activeCopy,setActiveCopy] = useState(false)
    const [deleteIndex,setDeleteIndex] = useState(-1)
    const [ifChanges,setIfChanges] = useState(false)

    const [copyMonth,setCopyMonth] = useState(optionsMonth3[new Date().getMonth()])
    const [copyYear,setCopyYear] = useState(optionsYear2[1])

    const [name,setName] = useState('')
    const [price,setPrice] = useState('')
    const [group,setGroup] = useState('')
    const [prefix,setPrefix] = useState('')

    const [empty,setEmpty] = useState([])
    const [onPrice,setOnPrice] = useState([])

    const {store} = useContext(Context)
    const message = useMessage()
    const navigate = useNavigate()

    const loadingHandler = async () => {
        try {
            setLoading(true)
            if(selectedYear && selectedMonth){
                const ogm = await ReferenceService.getOgm(selectedMonth.value,selectedYear.value)
                if(ogm.data) {
                    setOgmPrices(ogm.data.map(item => ({ ...item })))
                    setOgmPricesOld(ogm.data.map(item => ({ ...item })))
                }
            }else{
                message('Выберите месяц')
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const checkEmpty = () => {
        const n = [...empty]

        n[0] = !!!name.trim().length
        n[1] = !!!price.trim().length
        n[2] = !!!group.trim().length
        n[3] = !!!prefix.trim().length

        const hasTrueValue = n.some(value => value === true);
        if( hasTrueValue ) setEmpty(n)
        else setEmpty([])
        return hasTrueValue
    }
    const checkChange = () => {
        const n = [...onPrice]
        ogmPrices.map((item,index) => {
            n[index] = !!!item.price
        })
        const hasTrueValue = n.some(value => value === true);
        if( hasTrueValue ) setOnPrice(n)
        else setOnPrice([])
        return hasTrueValue
    }
    useEffect(() => {
        loadingHandler()
    },[])
    useEffect(() => {
        loadingHandler()
    },[selectedYear,selectedMonth])
    useEffect(() => {
        if (JSON.stringify(ogmPricesOld) === JSON.stringify(ogmPrices)) {
            setIfChanges(false)
        } else {
            setIfChanges(true)
        }
    },[ogmPrices])
    useEffect(() => {
        if(empty.length) checkEmpty()
    },[name,price,group,prefix])
    const changePriceHandler = (value,index) => {
        if(isNumeric(value)){
            const newOgms = [...ogmPrices]
            newOgms[index].price = value
            setOgmPrices(newOgms)
        }
    }
    const changeGroupHandler = (value,index) => {
        const newOgms = [...ogmPrices]
        newOgms[index].group = value
        setOgmPrices(newOgms)
    }
    const changePrefixHandler = (value,index) => {
        const newOgms = [...ogmPrices]
        newOgms[index].prefix = value
        setOgmPrices(newOgms)
    }

    const saveHandler = async () => {
        try {
            if(ifChanges){
                if(!checkChange()){
                    const response = await ReferenceService.saveOgm(ogmPrices)
                    if(response.data){
                        message('Тарифы обновлены')
                    }
                }else{
                    message('Поле стоимости не может быть пустым')
                }
            }
        }catch (e) {
            console.log(e)
        }
    }
    const createHandler = async () => {
        try {
            if(!checkEmpty()){
                const ogm = {name:name,price:+price,group:group,prefix:prefix,year:selectedYear.value,month:selectedMonth.value,inn:store.user.inn}
                const response = await ReferenceService.createOgm(ogm)
                if(response.data){
                    message('Тариф добавлен')
                    loadingHandler()
                    setName('')
                    setPrice('')
                    setGroup('')
                    setPrefix('')
                }
            }else{
                message('Заполните все поля')
            }

        }catch (e) {
            console.log(e)
        }
    }
    const deleteHandler = (index) => {
        setActiveDelete(true)
        setDeleteIndex(index)
    }

    function Delete() {
        const removeHandler = async () => {
            try{
                const response = await ReferenceService.deleteOgm(ogmPrices[deleteIndex].id)
                console.log(response.data)
                if(response.data.del) {
                    message('Тариф удален')
                    const newOgm = [...ogmPrices]
                    newOgm.splice(deleteIndex, 1)
                    setOgmPrices(newOgm)
                    exitDeleteHandler()
                }
            }catch (e){
                console.log(e.message+': Проблема удаления тарифа')
            }
        }
        const exitDeleteHandler = () => {
            setDeleteIndex(-1)
            setActiveDelete(false)
        }
        return(
            <>
                <div className='copy'>
                    <h4>Вы действительно хотели бы удалить тариф {deleteIndex >= 0 && ogmPrices[deleteIndex].name}</h4>
                    <div className='buttons'>
                        <div onClick={() => removeHandler()} className='button da'>Да</div>
                        <div onClick={() => exitDeleteHandler()} className='button'>Нет</div>
                    </div>
                </div>
            </>
        )
    }

    function Copy() {
        const copyHandler = async () => {
            try{
                setLoading(true)
                if(copyMonth.value !== selectedMonth.value || copyYear.value !== selectedYear.value){
                    const tarifs = await ReferenceService.copyTarifMonth(selectedMonth.value,selectedYear.value,copyMonth.value,copyYear.value)
                    console.log(tarifs)
                    if(tarifs){
                        message('Тарифы скопированы')
                        setActiveCopy(false)
                        setCopyYear(selectedYear)
                        setCopyMonth(selectedMonth)
                    }
                }else{
                    message('Выбраный месяц или год, совпадает с копируемым')
                }

            }catch (e){
                console.log(e.message+': Проблема переноса тарифов')
            }finally {
                setLoading(false)
            }
        }
        const exitCopyHandler = () => {
            setCopyMonth(-1)
            setCopyYear(-1)
            setActiveCopy(false)
        }
        return(
            <>
                <div className={s.copy}>
                    <h4>Выерите месяц и год для переноса тарифов</h4>
                    <div className={s.selectcontainer}>
                        <CmsSelect placeholder={'Месяц'} onChange={(e) => setCopyMonth(e)} options={optionsMonth3} value={copyMonth}/>
                        <CmsSelect placeholder={'Год'} onChange={(e) => setCopyYear(e)} options={optionsYear2} value={copyYear}/>
                    </div>

                    <div className={s.buttons}>
                        <div onClick={() => copyHandler()} className={`${s.button}`}>Перенести</div>
                        <div onClick={() => exitCopyHandler()} className={`${s.button} ${s.da}`}>Выйти</div>
                    </div>
                </div>
            </>
        )
    }
    const changeYearHandler = (index) => {
        setSelectedYear(optionsYear2[index])
    }
    const changeMonthHandler = (index) => {
        setSelectedMonth(optionsMonth3[index])
    }
    const copyTarif = () => {
        setActiveCopy(true)
    }

    return (
        <div className={s.container}>
            <div className={s.title}>Выберите год</div>
            <div className={s.btns}>
                {optionsYear2.map( (item,index) => (
                    <div key={index} onClick={() => changeYearHandler(index)} className={`${s.yearbutton} ${selectedYear === item && s.active}`}>{item.label}</div>
                ))}
            </div>
            <div className={s.title}>Выберите месяц</div>
            <div className={s.btns}>
                {optionsMonth3.map((item,index) => (
                    <div key={index} onClick={() => changeMonthHandler(index)} className={`${s.monthbutton} ${selectedMonth === item && s.active}`}>{item.label}</div>
                ))}
            </div>
            <div className={s.title}>Перенести (скопировать)</div>
            <div>
                <div className={s.btns}>
                    <div onClick={copyTarif} className={`${s.monthbutton} ${s.actionbutton}`}>Перенести</div>
                </div>
            </div>
            <div className={s.title}>Список и тарифы по транспорту</div>
            <div className={s.ogmlistbtns}>
                <div onClick={(e) => saveHandler()} className={`${s.ogmlistsave} ${!ifChanges && s.disable}`}>Сохранить</div>
            </div>

            <div className={s.ogmlistlist}>
                <div className={s.ogmlistlistline}>
                    <div className={`${s.ogmlistlistlinename} ${s.nametitle}`}>Транспорт</div>
                    <div className={`${s.ogmlistlistlineprice} ${s.title}`}>Стоимость</div>
                    <div className={`${s.ogmlistlistlinegroup} ${s.title}`}>Группа</div>
                    <div className={`${s.ogmlistlistlinecropname} ${s.title} ${s.borderrightnone}`}>Сокр. название</div>
                </div>
                {ogmPrices && ogmPrices.map((item,index) => (
                    <div key={index} className={`${s.ogmlistlistline} ${s.bordertopnone}`}>
                        <div className={`${s.ogmlistlistlinename}`}>{item.name}</div>
                        <input className={`${s.ogmlistlistlineprice} ${s.inputs} ${onPrice[index] && 'red-solid-border'}`} onChange={(e) => changePriceHandler(e.target.value,index)} value={item.price}/>
                        <input className={`${s.ogmlistlistlinegroup} ${s.inputs}`} onChange={(e) => changeGroupHandler(e.target.value,index)} value={item.group}/>
                        <input className={`${s.ogmlistlistlinecropname} ${s.inputs}`} onChange={(e) => changePrefixHandler(e.target.value,index)} value={item.prefix}/>
                        <div onClick={(e) => deleteHandler(index)} className={s.ogmlistlistlinedel}><i className="fa-solid fa-xmark"></i></div>
                    </div>
                ))}
            </div>
            <div className={s.newlineogm}>
                <div className={s.line}>
                    <input className={`${s.ogmlistlistlinenew} ${empty[0] && 'red-solid-border'}`} value={name} onChange={(e) => setName(e.target.value)} placeholder="Наименование транспорта"/>
                    <input className={`${s.ogmlistlistlinenew} ${empty[1] && 'red-solid-border'}`} value={price} onChange={(e) => {if(isNumeric(e.target.value)) setPrice(e.target.value)}} placeholder="Ставка, руб"/>
                    <input className={`${s.ogmlistlistlinenew} ${empty[2] && 'red-solid-border'}`} value={group} onChange={(e) => setGroup(e.target.value)} placeholder="Группа транспорта"/>
                    <input className={`${s.ogmlistlistlinenew} ${empty[3] && 'red-solid-border'}`} value={prefix} onChange={(e) => setPrefix(e.target.value)} placeholder="Сокр.наименование"/>
                </div>
                <div className={s.line}>
                    <div className={s.linetitle}>Добавить транспорт</div>
                    <div onClick={(e) => createHandler()} className={s.ogmlistplus}>Добавить</div>
                </div>
            </div>
            <ModalFiles data={<Copy />} active={activeCopy} setActive={setActiveCopy}/>
            <ModalFiles data={<Delete />} active={activeDelete} setActive={setActiveDelete}/>
            {loading ? (<LoadingSpinner/>) : null}
        </div>
    )
}

export default TransportTarif