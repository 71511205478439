import "./style.scss"
import React,{useState} from "react";
import {useEffect} from "react";
import AuthServise from "../../services/AuthService";
import UserService from "../../services/UserService";
import StatusIcon2 from "../icons/StatusIcon2";
import SendGift from "../inputs/SendGift";

export const BirthDay = () => {
    const [birthman, setBirthman] = useState([])

    const [giftActive,setGiftActive] = useState(false)
    const [giftUser,setGiftUser] = useState(null)

    const currentDate = new Date();
    function isBirthdayWithin30Days(birthdate) {
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear()
        let birthdayThisYear = new Date(currentYear, birthdate.getMonth(), birthdate.getDate())
        if (birthdayThisYear < currentDate) {
            birthdayThisYear.setFullYear(currentYear + 1)
        }
        let differenceInDays = Math.round((birthdayThisYear - currentDate) / (1000 * 60 * 60 * 24))
        return differenceInDays <= 7 && differenceInDays >= 0
    }
    function calculateAge(birthday) {
        const dayarr = birthday.split(".")
        const day = parseInt(dayarr[0])
        const mon = parseInt(dayarr[1])
        const ye = parseInt(dayarr[2])
        let birthdate = new Date(ye, mon - 1, day)
        let currentDate = new Date()
        let currentYear = currentDate.getFullYear()
        let birthdayThisYear = new Date(currentYear, birthdate.getMonth(), birthdate.getDate())

        if (birthdayThisYear > currentDate) {
            currentYear -= 1
            birthdayThisYear = new Date(currentYear, birthdate.getMonth(), birthdate.getDate())
        }

        return currentYear - birthdate.getFullYear();

    }
    function compareBirthdays(a, b) {
        const [dayA, monthA] = a.birthday.split('.').slice(0, 2).map(Number);
        const [dayB, monthB] = b.birthday.split('.').slice(0, 2).map(Number);
        if (monthA === monthB) {
            return dayA - dayB; // Если месяцы одинаковые, сравниваем по дням
        } else {
            return monthA - monthB; // Иначе сравниваем по месяцам
        }
    }
    const selectGift = (user) => {
        setGiftActive(true)
        setGiftUser(user)
    }
    const t13List = async () => {
        const newArr = []
        try {
            const listMan = await UserService.getUni()
            listMan.data.forEach(man => {
                const dayarr = man.birthday.split(".")
                const day = parseInt(dayarr[0])
                const mon = parseInt(dayarr[1])
                const ye = parseInt(dayarr[2])
                let date = new Date(ye, mon - 1, day)
                const monthnow = currentDate.getMonth() + 1
                const monthnis = date.getMonth() + 1
                const daynow = ''+currentDate.getDate()+'.'+monthnow
                const hisbirthday = ''+date.getDate()+'.'+monthnis
                if(isBirthdayWithin30Days(date) || daynow === hisbirthday){
                    man.avatar = 'face.png'
                    man.burn = man.birthday
                    newArr.push(man)
                }
            })
            const list = await AuthServise.getusers()

            if(list.data){
                newArr.map( item => {
                    list.data.users.map( row => {
                        if(item.tn === row.tn){
                            item.avatar = row.avatar ? row.avatar : 'face.png'
                            item.user_id = row.id
                        }
                    })
                })
            }
            newArr.sort(compareBirthdays)
            setBirthman(newArr ? [...newArr] : [])
        } catch(e) {
            console.log(e)
        }
    }
    useEffect(() => {
        t13List()

    }, [])

    return (
        <div className="frame_block ">
            <div className="frame_block_title">
                <div id={`happybirthday`} className="frame_block_title_name">Дни рождения <i className="fa-solid fa-gift"></i></div>
            </div>

            <div className="frame_block_birthday borderbirthday birthdaycolor">
                <div className="frame_block_birthday_main">
                    {birthman.map((man, index) => {
                        return (
                        <div className="frame_block_birthday_main_man" key={index}>
                            <div className="frame_block_birthday_main_man_photo" style={{backgroundImage: `url("/files/profile/${man.avatar}")`,position:'relative'}}>
                                <StatusIcon2 tn={man.tn} />
                            </div>
                            <div className="frame_block_birthday_main_man_text">
                                <div className="frame_block_birthday_main_man_name">{man.name}</div>
                                <div className="frame_block_birthday_main_man_dev">{man.developer}</div>
                                <div className="frame_block_birthday_main_man_date">{man.birthday}</div>
                                <div className="frame_block_birthday_main_man_years">{calculateAge(man.birthday)}</div>
                                {man.user_id ? <div onClick={() => selectGift(man)} className="frame_block_birthday_button"><i className="fa-solid fa-cake-candles"></i>Отправить подарок<i className="fa-solid fa-cake-candles"></i></div> : null }
                            </div>
                        </div>
                    )})}
                </div>
            </div>
            {giftActive ? <SendGift active={giftActive} setActive={setGiftActive} user={giftUser} type={0}/> : null }
        </div>
    )
}