import "./style.scss"
import {Link} from "react-router-dom";
import React,{useContext, useEffect, useState} from "react";
import {DataContext} from "../../context/DataContext";
import {Context} from "../../index";
import MessagesService from "../../services/MessagesService";
import {getSocket} from "../../http/socket";
import SocialService from "../../services/SocialService";
import UserService from "../../services/UserService";

export const Mainnavbar = ({path = ''}) => {
    const [newmess, setNewmess] = useState(0)
    const {selectedMenu,setSelectedMenu} = useContext(DataContext)
    const {store} = useContext(Context)
    const rule = store.user.unit
    const my_tn = store.user.tn
    const my_id = store.user.id
    const [commission_ids, setCommission_ids] = useState([])
    const [yesZa, setYesZa] = useState(false)

    const searchMess = async () => {
        try{
            const {data} = await MessagesService.searchNewMess()
            setNewmess(data)
        }catch(e){
            console.log(e)
        }
    }
    const getCommissions = async () => {
        try{
            const {data} = await SocialService.getComission()
            const listmans = []
            data.forEach(man => {
                listmans.push(man.user.id)
            })
            if(listmans.includes(my_id)){
                setYesZa(true)
            }
        }catch(e){

        }
    }
    const myid = store.user.id
    const [listmans, setListMans] = useState([])
    const [openSV, setOpenSV] = useState(false)
    const [svMan, setSvMan] = useState(undefined)
    const [svid, setSvid] = useState(0)
    const findObjectById = (id, array) => {
        return array.find(item => item.user_id === id);
    }

    const uploadList = async () => {
        try{
            const {data} = await UserService.getPoint()
            setListMans(data.list)

            const foundObject = findObjectById(myid, data.list)
            if(foundObject){
                setOpenSV(true)
                setSvMan(foundObject)
                setSvid(foundObject.user_id)
            }
        }catch(e){

        }
    }
    useEffect(()=>{
        uploadList()
        searchMess()
        getCommissions()
        const socket = getSocket()
        socket.on('receiveMessage', () => {
            setNewmess(1)
        })
    }, [])
    return (
        <div className={`navbar_block ${path==='welding' ? 'hidden-menu' : ''}`}>
            <div className="navbar_block_menu">
                <Link to='/' onClick={(e) => setSelectedMenu(1)} className={`navbar_block_menu_strock ${selectedMenu===1 && 'selected'}`}>
                    <div className="navbar_block_menu_strock_icon icon_home"></div>
                    <div className="navbar_block_menu_strock_description">Главная</div>
                </Link>
                <Link to='/alllistnews' onClick={(e) => setSelectedMenu(2)} className={`navbar_block_menu_strock ${selectedMenu===2 && 'selected'}`}>
                    <div className="navbar_block_menu_strock_icon icon_news"></div>
                    <div className="navbar_block_menu_strock_description">Новости</div>
                </Link>

                    <Link to="/messages" onClick={(e) => setSelectedMenu(3)}
                          className={`navbar_block_menu_strock ${selectedMenu === 3 && 'selected'}`}>
                        <div className="navbar_block_menu_strock_icon icon" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#FFF',
                            fontSize: '16pt'
                        }}><i className="fa-regular fa-comment"></i>{ newmess ? <div className="messindicate"> </div> : null} </div>
                        <div className="navbar_block_menu_strock_description">Сообщения</div>
                    </Link>

                <Link to="/structure" onClick={(e) => setSelectedMenu(4)} className={`navbar_block_menu_strock ${selectedMenu===4 && 'selected'}`}>
                    <div className="navbar_block_menu_strock_icon icon_forest"></div>
                    <div className="navbar_block_menu_strock_description">Структура компании</div>
                </Link>
                <Link onClick={(e) => setSelectedMenu(5)} to='/documents' className={`navbar_block_menu_strock ${selectedMenu===5 && 'selected'}`}>
                    <div className="navbar_block_menu_strock_icon icon_doc"></div>
                    <div className="navbar_block_menu_strock_description">Документы</div>
                </Link>

                <Link onClick={(e) => setSelectedMenu(8)} to="/newpaylist" className={`navbar_block_menu_strock ${selectedMenu===8 && 'selected'}`}>
                    <div className="navbar_block_menu_strock_icon" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '16pt'}}><i className="fa-brands fa-google-wallet"></i></div>
                    <div className="navbar_block_menu_strock_description">Расчётка</div>
                </Link>
                <Link onClick={(e) => setSelectedMenu(9)} to="/about" className={`navbar_block_menu_strock ${selectedMenu===9 && 'selected'}`}>
                    <div className="navbar_block_menu_strock_icon" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '16pt'}}><i className="fa-solid fa-circle-info"></i></div>
                    <div className="navbar_block_menu_strock_description">О компании</div>
                </Link>

            </div>
            <div className={`navbar_block_dopmenu`}>
                <div className={`navbar_block_dopmenu_list`}>
                    <a href={`/#happybirthday`} className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-cake-candles"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Дни рождения</div>
                    </a>
                    <Link to='/polls' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-toggle-off"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Опросы</div>
                    </Link>
                    <Link to={'/statements'} className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-brands fa-slack"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Бланки заявлений</div>
                    </Link>
                    <Link style={(store.user.editcom === 'projects' || store.user.account === 'superadmin')?{display: 'flex'}:{display: 'none'}} to='/common' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-diagram-project"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Обмен проектами</div>
                    </Link>
                    {(rule === 99 || rule === 3 || store.user.account === 'superadmin') &&
                    <Link to={'/createnews'} className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-thumbtack"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Добавить новость</div>
                    </Link>
                    }
                    {/*<Link to='/kids-contest' className={`navbar_block_dopmenu_list_strock`}>*/}
                    {/*    <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-trophy"></i></div>*/}
                    {/*    <div className="navbar_block_dopmenu_list_description">Конкурс</div>*/}
                    {/*</Link>*/}
                    <Link style={(rule === 1 || rule === 2 || rule === 3 || rule === 5 || rule === 7 || store.user.account === 'superadmin' || !!store.pointsv)?{display: 'flex'}:{display: 'none'}} to='/objectsportal' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-object-ungroup"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Объекты</div>
                    </Link>
                    <Link style={(rule === 5 || store.user.account === 'superadmin')?{display: 'flex'}:{display: 'none'}} to='/weldingmenu' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-wand-magic-sparkles"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Сварщик</div>
                    </Link>
                    <Link style={(rule === 3 || rule === 7 || store.user.account === 'superadmin')?{display: 'flex'}:{display: 'none'}} to='/economist' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-coins"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Экономист</div>
                    </Link>
                    <Link style={(rule === 3 || rule === 4 || rule === 7 || store.user.account === 'superadmin')?{display: 'flex'}:{display: 'none'}} to='/hr' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-person-circle-plus"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Кадры</div>
                    </Link>
                    <Link style={(rule === 99 || store.user.account === 'superadmin')?{display: 'flex'}:{display: 'none'}} to='/editor' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-user-pen"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Редактор</div>
                    </Link>

                    <Link style={(rule === 99 || rule === 100 || store.user.account === 'superadmin' || yesZa)?{display: 'flex'}:{display: 'none'}} to='/statementsmenu' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-receipt"/></div>
                        <div className="navbar_block_dopmenu_list_description">Заявления</div>
                    </Link>
                    <Link style={(store.user.account === 'superadmin' || store.user.account === 'analyst')?{display: 'flex'}:{display: 'none'}} to='/analytics' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-chart-simple"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Аналитика</div>
                    </Link>

                    <Link style={(store.user.account === 'reader' || store.user.account === 'superadmin')?{display: 'flex'}:{display: 'none'}} to='/itogtabel' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-table-list"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Табель</div>
                    </Link>
                    <Link style={(store.user.account === 'fixers' || store.user.account === 'superadmin')?{display: 'flex'}:{display: 'none'}} to='/fixers' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-user-secret"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Фиксики</div>
                    </Link>

                    <Link to='/galary' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-camera-retro"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Галерея</div>
                    </Link>
                    <Link style={(store.user.account === 'superadmin')?{display: 'flex'}:{display: 'none'}} to='/cms' className={`navbar_block_dopmenu_list_strock`}>
                        <div className="navbar_block_dopmenu_list_icon"><i className="fa-solid fa-screwdriver-wrench"></i></div>
                        <div className="navbar_block_dopmenu_list_description">Администрирование</div>
                    </Link>
                </div>

                {/*<div className="navbar_block_dopmenu_more">Ещё...</div>*/}
            </div>
        </div>
    )
}