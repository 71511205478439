import React from "react";
/**
 * Проверяет, позднее ли дата createdAt указанной даты
 * @param {string|Date} date - Дата для проверки
 * @returns {boolean} - true, если дата позднее 27.11.2024, иначе false
 */
export default function checkNovember2024(date){
    if(date){
        const targetDate = new Date('2024-11-27')
        const dateToCheck = new Date(date)
        if (isNaN(dateToCheck)) {
            throw new Error('Некорректная дата')
        }
        return dateToCheck > targetDate
    }
    return false
}