import React, { createContext, useState } from 'react';
import {ToastContainer} from "react-toastify";


export const DataContext = createContext('')

export const DataProvider = ({ children }) => {
    const mass_create_bridge = [
        {
            link:'/',
            text:'Создать задачу'
        },
        {
            link:'/',
            text:'Создать документ'
        },
        {
            link:'/',
            text:'Создать Проект'
        },
        {
            link:'/',
            text:'Создать перемещение'
        }

    ]
    const menu_mass_bridge = [
        {
            link:'/main',
            text:'Входящие документы',
            img:'menuimg1',
            num:4
        },{
            link:'/main',
            text:'Проект (Обновления)',
            img:'menuimg2',
            num:1
        },{
            link:'/main',
            text:'Отчеты',
            img:'menuimg3',
            num:0
        },{
            link:'/main',
            text:'Статистика',
            img:'menuimg4',
            num:0
        },
    ]
    const mass_create = [
        {
            link:'/createtask',
            text:'Создать задачу',
            icon:'+'
        },
        {
            link:'/createtask',
            text:'Создать документ',
            icon:'+'
        },
        {
            link:'/createtask',
            text:'Создать Проект',
            icon:'+'
        },
        {
            link:'/createtask',
            text:'Создать перемещение',
            icon:'+'
        },{
            link:'/dashboard',
            text:'Панель Администратора',
            icon:''
        }
    ]
    const menu_mass = [
        {
            link:'/docpasslist',
            text:'Документо оборот',
            img:'menuimg1',
            num:4
        },{
            link:'/main',
            text:'Проект (Обновления)',
            img:'menuimg2',
            num:1
        },{
            link:'/main',
            text:'Отчеты',
            img:'menuimg3',
            num:0
        },{
            link:'/main',
            text:'Статистика',
            img:'menuimg4',
            num:0
        },
    ]
    const wrap_buttons = [
        {
            text:'Создать задачу',
            icon:'fa-regular fa-plus',
            url:'/createtask'
        },
        {
            text:'Полученные задания',
            icon:'fa-solid fa-arrow-left',
            url:''
        },
        {
            text:'Ежедневник',
            icon:'fa-regular fa-calendar-days',
            url:''
        },
        {
            text:'Направленные задания',
            icon:'fa-solid fa-arrow-right',
            url:'',
        },
    ]
    const task1 = {
        status:3,
        datestart:'01.01.2024',
        dateend:'10.01.2024',
        key:'1044-5',
        level:3,
        link:'/',
        title:'Отремонтировать технику',
        text:'Первым этапом капитального ремонта является разборка и очистка двигателя. Затем нужно выполнить дефектовку, включающую в себя оценку выработки, измерение зазоров, проверку состояния головки блока цилиндров, блока цилиндров и определенных деталей на предмет наличия дефектов и износа, и т.д. После этого производится сравнение состояния деталей с заводскими допусками.'
    }
    const attach1 = {
        1:{
            name:'план',
            type:'jpg',
            link:'/'
        },
        2:{
            name:'согласование',
            type:'pdf',
            link:'/'
        },
        3:{
            name:'расчеты',
            type:'xlsx',
            link:'/'
        },
        4:{
            name:'приложение1',
            type:'docx',
            link:''
        },
        5:{
            name:'приложение2',
            type:'docx',
            link:''
        },
        6:{
            name:'статистика',
            type:'xlsx',
            link:''
        },
        7:{
            name:'Зарплатa',
            type:'xlsx',
            link:''
        }
    }
    const performers = {
        main: {
            name:'Романов Сергей Владимирович',
            date:'12.09.2023 / 12:33',
            job:'Зам. главного механика'
        },
        works: {
            1:{
                name: 'Ремонт техники',
                link: '/'
            },
            2:{
                name: 'Организация планирования процессов',
                link: '/'
            },
            3:{
                name: 'Разработка стратегического развития',
                link: '/'
            },
            4:{
                name: 'Расчет объема и стоимости материалов',
                link: '/'
            },
        },
        people: {
            1:{
                name: 'Труфанова Елена Васильевна',
                job: 'Старший механик'
            },
            2:{
                name: 'Григорьева Екатерина Васильевна',
                job: 'Механик'
            }
        }
    }
    const results = {
        attaches:attach1,
        text:'Поврежденную при теракте Киева левую часть Крымского моста отремонтировали на один день раньше заявленного срока. Об этом сообщил вице-премьер РФ Марат Хуснуллин. По его словам, движение транспорта полностью возобновлено. «На восстановление нам потребовалось всего 59 дней! На совещании у президента докладывали, что завершим работу к 15 сентября, и точно выдержали график, закончили даже на 1 день раньше!» — написал Хуснуллин в Telegram. Он отметил, что к восстановительным работам приступили уже в день теракта. «Теперь левая часть полностью готова принять поток автомобилей», — заключил Хуснуллин. Взрыв на Крымском мосту произошел в ночь на 17 июля. В результате ЧП обрушились два автомобильных пролета, четыре человека погибли. Национальный антитеррористический комитет (НАК) признал произошедшее терактом ВСУ.'
    }
    const dwm2 =
        {
            1:{
                name:'Иванов Иван Иванович',
                date:'12.09.2023 / 12:33',
                job:'Механик',
                status:'Подписано',
                next:[9]
            },
            9:{
                name:'Романов Сергей Владимирович',
                date:'12.09.2023 / 12:33',
                job:'Зам. главного механика',
                status:'Подписано',
                next:[2]
            },
            2:{
                name:'Семенов Сергей Владимирович',
                date:'12.09.2023 / 12:33',
                job:'Зам. главного механика',
                status:'Подписано',
                next:[3,4,5]
            },
            3:{
                name:'Андреев Андрей Андреевич',
                date:'12.09.2023 / 12:33',
                job:'Начальник',
                status:'Подписано',
                next:[6]
            },
            4:{
                name:'Константинов Константин Константинович',
                date:'12.09.2023 / 12:33',
                job:'Начальник',
                status:'Подписано',
                next:[6]
            },
            5:{
                name:'Макаров Александр Владимирович',
                date:'12.09.2023 / 12:33',
                job:'Генеральный директор',
                status:'Подписано',
                next:[6]
            },
            6:{
                name:'Гаврилова Наталья Владимировна',
                date:'12.09.2023 / 12:33',
                job:'Главный бухгалтер',
                status:'Подписано',
                next:[7]
            },
            7:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:null
            }
        }
    const dwm1 =
        {
            1:{
                name:'Иванов Иван Иванович',
                date:'12.09.2023 / 12:33',
                job:'Механик',
                status:'Подписано',
                next:[9]
            },
            9:{
                name:'Романов Сергей Владимирович',
                date:'12.09.2023 / 12:33',
                job:'Зам. главного механика',
                status:'Подписано',
                next:[2]
            },
            2:{
                name:'Романов Сергей Владимирович',
                date:'12.09.2023 / 12:33',
                job:'Зам. главного механика',
                status:'Подписано',
                next:[3,4,5,6]
            },
            3:{
                name:'Андреев Андрей Андреевич',
                date:'12.09.2023 / 12:33',
                job:'Начальник',
                status:'Подписано',
                next:[7]
            },
            4:{
                name:'Константинов Константин Константинович',
                date:'12.09.2023 / 12:33',
                job:'Начальник',
                status:'Подписано',
                next:[7]
            },
            5:{
                name:'Макаров Александр Владимирович',
                date:'12.09.2023 / 12:33',
                job:'Генеральный директор',
                status:'Подписано',
                next:[7]
            },
            6:{
                name:'Гаврилова Наталья Владимировна',
                date:'12.09.2023 / 12:33',
                job:'Главный бухгалтер',
                status:'Подписано',
                next:[7]
            },
            7:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[13]
            },
            13:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[10,11,12]
            },
            10:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[14]
            },
            11:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[14]
            },
            12:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[14]
            },
            14:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[15]
            },
            15:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[17,16]
            },
            16:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[18]
            },
            17:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[18]
            },
            18:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[19,20,21]
            },
            19:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[22]
            },
            20:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[22]
            },
            21:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[22]
            },
            22:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[23]
            },
            23:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[31,24,25,26,27,28,29,30]
            },
            24:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[32]
            },
            25:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[32]
            },
            26:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[32]
            },
            27:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[32]
            },
            28:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[32]
            },
            29:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[32]
            },
            30:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[32]
            },
            31:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[32]
            },
            32:{
                name:'Кузнецова Татьяна Александровна',
                date:'12.09.2023 / 12:33',
                job:'Бухгалтер',
                status:'Подписано',
                next:[8]
            },
            8:{
                name:'Петров Василий Михайлович',
                date:'12.09.2023 / 12:33',
                job:'Аутсорс',
                status:'Не подписано',
                next:null
            }
        }
    const mass_blocks = [
        {
            link: '/',
            text: 'Мои задачи планировщик'
        },
        {
            link: '/',
            text: 'Файловый менеджер'
        },
        {
            link: '/objects',
            text: 'Управление проектами'
        },
        {
            link: '/',
            text: 'Совещания'
        },
        {
            link: '/',
            text: 'ТМЦ Перемещения'
        },
        {
            link: '/',
            text: 'Документо оборот'
        }
    ]
    const mass_blocks_admin = [
        {
            link: '/createobject',
            text: 'Создать Обьект'
        },
        {
            link: '/',
            text: 'Файловый менеджер'
        },
        {
            link: '/',
            text: 'Загрузка данных'
        },
        {
            link: '/',
            text: 'Почта'
        },
        {
            link: '/',
            text: 'Полномочия'
        },
        {
            link: '/',
            text: 'Табеля'
        },
        {
            link: '/',
            text: 'Настройки'
        },
        {
            link: '/',
            text: 'Пользователи'
        }
    ]
    const titlegraph1 = 'Задачи'
    const graphmass1 = [
        {
            text:'Выполнено',
            num:'20'
        },
        {
            text:'В работе',
            num:'18'
        },
        {
            text:'Новые',
            num:'26'
        },
        {
            text:'Просрочено',
            num:'5'
        }]
    const titlegraph2 = 'Документы'
    const graphmass2 = [
        {
            text:'Исходящие',
            num:'20'
        },
        {
            text:'Входящие',
            num:'18'
        },
        {
            text:'Возвраты',
            num:'26'
        },
        {
            text:'Просрочено',
            num:'5'
        }]
    const calendarmass = [
        {
            date:'27.12.2023',
            status:'1',
            title:'Задача №17',
            text:'Забрать картриджи в ДНС'
        }, {
            date:'17.12.2023',
            status:'2',
            title:'Задача №16',
            text:'Сдать отчет по оптимизации бизнес процессов'
        }
    ]
    const works1mass = [
        {
            datestart:'27.12.2023',
            dateend:'10.01.2024',
            viewed:'0',
            level:'повышенный',
            link:'/',
            title:'Задача №17',
            text:'Забрать картриджи в ДНС'
        }, {
            datestart:'27.12.2023',
            dateend:'10.01.2024',
            viewed:'1',
            level:'средний',
            link:'/',
            title:'Задача №16',
            text:'Сдать отчет по оптимизации бизнес процессов? Созвон с Организациями, Подготовить техническое задание'
        }, {
            datestart:'16.12.2023',
            dateend:'18.12.2023',
            viewed:'1',
            level:'средний',
            link:'/',
            title:'Задача №16',
            text:'Сдать отчет по оптимизации бизнес процессов'
        }, {
            datestart:'27.12.2023',
            dateend:'10.01.2024',
            viewed:'1',
            level:'средний',
            link:'/',
            title:'Задача №16',
            text:'Сдать отчет по оптимизации бизнес процессов'
        }
    ]
    const works2mass = [
        {
            datestart:'27.12.2023',
            dateend:'10.01.2024',
            direction:'0',
            level:'средний',
            link:'/',
            title:'Документ поступления',
            name:'Смирнов Петр Васильевич'
        }, {
            datestart:'01.12.2023',
            dateend:'16.12.2023',
            direction:'1',
            level:'повышенный',
            link:'/',
            title:'Отчет за Декабрь',
            name:'Иванов Дмитрий Сергеевич'
        }, {
            datestart:'16.12.2023',
            dateend:'18.12.2023',
            direction:'2',
            level:'повышенный',
            link:'/',
            title:'Справка №12',
            name:'Володин Вячеслав Андреевич'
        }, {
            datestart:'27.12.2023',
            dateend:'10.01.2024',
            direction:'1',
            level:'средний',
            link:'/',
            title:'Отчет',
            name:'Петрова Ирина Владимировна'
        }
    ]
    const works3mass = [
        {
            datestart:'27.12.2023',
            dateend:'10.01.2024',
            direction:'0',
            level:'средний',
            link:'/main',
            title:'Картриджи',
            text:'Краткое описание, краткое описание...',
            status:'в пути'
        }, {
            datestart:'01.12.2023',
            dateend:'16.12.2023',
            direction:'1',
            level:'повышенный',
            link:'/main',
            title:'Трубы',
            text:'Краткое описание, краткое описание...',
            status:'на сборе'
        }, {
            datestart:'16.12.2023',
            dateend:'18.12.2023',
            direction:'0',
            level:'повышенный',
            link:'/main',
            title:'Канцелярия',
            text:'Краткое описание, краткое описание...',
            status:'получен'
        }, {
            datestart:'27.12.2023',
            dateend:'10.01.2024',
            direction:'1',
            level:'средний',
            link:'/main',
            title:'Болтики',
            text:'Краткое описание, краткое описание...',
            status:'доставлен'
        }
    ]
    const list_titletasks = [
        {
            id: '1',
            datestart:'11.09.2023',
            dateend:'13.09.2023',
            timestart:'16:30',
            timeend:'11:32',
            title:'Подготовить тех документацию по SpaceX',
            navigation:'0',
            percent: '100',
            time: ['180', '03'],
            status: 'Завершена',
            group: 'Сбор информации',
            priority:'1',
            project:'386',
            content:'Содержание задания'
        },

        {
            id: '2',
            datestart:'11.09.2023',
            dateend:'13.09.2023',
            timestart:'16:30',
            timeend:'11:32',
            title:'Отремонтировать трубоукладчик 3шт',
            navigation:'1',
            percent: '70',
            time: ['0', '0'],
            status: 'Принята',
            group: 'Сбор информации',
            priority:'2',
            project:'400',
            content:'Содержание задания'
        },

        {
            id: '3',
            datestart:'11.09.2023',
            dateend:'13.09.2023',
            timestart:'16:30',
            timeend:'11:32',
            title:'Подготовить тех документацию по SpaceX',
            navigation:'0',
            percent: '0',
            time: ['180', '03'],
            status: 'Отменена',
            group: 'Сбор информации',
            priority:'3',
            project:'400',
            content:'Содержание задания'
        },

        {
            id: '4',
            datestart:'11.09.2023',
            dateend:'13.09.2023',
            timestart:'16:30',
            timeend:'11:32',
            title:'Отремонтировать трубоукладчик 3шт',
            navigation:'0',
            percent: '70',
            time: ['59', '30'],
            status: 'Завершена',
            group: 'Сбор информации',
            priority:'2',
            project:'377',
            content:'Содержание задания'
        },

        {
            id: '5',
            datestart:'11.09.2023',
            dateend:'13.09.2023',
            timestart:'16:30',
            timeend:'11:32',
            title:'Подготовить тех документацию по SpaceX',
            navigation:'0',
            percent: '100',
            time: ['120', '10'],
            status: 'Завершена',
            group: 'Сбор информации',
            priority:'1',
            project:'390',
            content:'Содержание задания'
        },

        {
            id: '6',
            datestart:'11.09.2023',
            dateend:'13.09.2023',
            timestart:'16:30',
            timeend:'11:32',
            title:'Отремонтировать трубоукладчик 3шт',
            navigation:'1',
            percent: '70',
            time: ['95', '02'],
            status: 'Завершена',
            group: 'Сбор информации',
            priority:'1',
            project:'390',
            content:'Содержание задания'
        },

        {
            id: '7',
            datestart:'11.09.2023',
            dateend:'13.09.2023',
            timestart:'16:30',
            timeend:'11:32',
            title:'Подготовить тех документацию по SpaceX',
            navigation:'3',
            percent: '100',
            time: ['46', '00'],
            status: 'Завершена',
            group: 'Сбор информации',
            priority:'1',
            project:'386',
            content:'Содержание задания'
        },

        {
            id: '8',
            datestart:'11.09.2023',
            dateend:'13.09.2023',
            timestart:'16:30',
            timeend:'11:32',
            title:'Отремонтировать трубоукладчик 3шт',
            navigation:'0',
            percent: '0',
            time: ['21', '00'],
            status: 'Новое',
            group: 'Сбор информации',
            priority:'2',
            project:'377',
            content:'Содержание задания'
        },

        {
            id: '9',
            datestart:'11.09.2023',
            dateend:'13.09.2023',
            timestart:'16:30',
            timeend:'11:32',
            title:'Подготовить тех документацию по SpaceX',
            navigation:'0',
            percent: '100',
            time: ['65', '23'],
            status: 'Завершена',
            group: 'Сбор информации',
            priority:'1',
            project:'377',
            content:'Содержание задания'
        },

        {
            id: '10',
            datestart:'11.09.2023',
            dateend:'13.09.2023',
            timestart:'16:30',
            timeend:'11:32',
            title:'Отремонтировать трубоукладчик 3шт',
            navigation:'0',
            percent: '60',
            time: ['11', '05'],
            status: 'Завершена',
            group: 'Сбор информации',
            priority:'2',
            project:'386',
            content:'Содержание задания'
        },

        {
            id: '11',
            datestart:'11.09.2023',
            dateend:'13.09.2023',
            timestart:'16:30',
            timeend:'11:32',
            title:'Подготовить тех документацию по SpaceX',
            navigation:'1',
            percent: '100',
            time: ['08', '20'],
            status: 'Завершена',
            group: 'Сбор информации',
            priority:'1',
            project:'386',
            content:'Содержание задания'
        },

        {
            id: '12',
            datestart:'11.09.2023',
            dateend:'13.09.2023',
            timestart:'16:30',
            timeend:'11:32',
            title:'Отремонтировать трубоукладчик 3шт',
            navigation:'0',
            percent: '50',
            time: ['09', '20'],
            status: 'Завершена',
            group: 'Сбор информации',
            priority:'2',
            project:'400',
            content:'Содержание задания'
        }
    ]
    const objs = [
        {
            id:1,
            name:'390',
            description:'"Участок магистрального нефтепровода "Нижневартовск-Курган-Куйбышев" 284км. - 332км. Ду1200. Замена трубы на ППМТ 294км. р.М.Балык (пойма/русло). Нефтеюганское УМН. Реконструкция"',
            dateinto: '16-04-2024'
        },
        {
            id:2,
            name:'391',
            description:'Участок магистрального нефтепровода "Сургут-Полоцк" 76 км-137 км. Замена трубы на участке 87-100км. DN1200. Нефтеюганское УМН. Реконструкция',
            dateinto: '17-04-2024'
        },
        {
            id:3,
            name:'392',
            description:'"Участок магистрального нефтепровода "Усть-Балык-Нижневартовск" 276км. Пр.Б.Юганская (пойма/русло). Реконструкция"',
            dateinto: '20-04-2024'
        },
        {
            id:4,
            name:'393',
            description:'"Участок магистрального нефтепровода "Усть-Балык-Нижневартовск" 233км-280км. Нефтеюганское УМН."',
            dateinto: '14-04-2024'
        },
        {
            id:5,
            name:'394',
            description:'Нижневартовск" 233км-280км. Нефтеюганское УМН.""Основная нитка, Ду1020. Замена трубы на ППМН, 233км р.Обь(пойма)',
            dateinto: '22-04-2024'
        },
        {
            id:6,
            name:'395',
            description:'"Участок магистрального нефтепровода "Усть-Балык-Нижневартовск" 219км-233км. Сургутское УМН."',
            dateinto: '25-04-2024'
        },
        {
            id:7,
            name:'396',
            description:'РВСП 20000 м3 №4 НПС "Холмогоры". Ноябрьское УМН. Техническое перевооружение',
            dateinto: '24-04-2024'
        }
    ]
    const my_objs = [
        {
            id:11,
            name:'390',
            description:'"Участок магистрального нефтепровода "Нижневартовск-Курган-Куйбышев" 284км. - 332км. Ду1200. Замена трубы на ППМТ 294км. р.М.Балык (пойма/русло). Нефтеюганское УМН. Реконструкция"',
            dateinto: '16-04-2024'
        },
        {
            id:2,
            name:'391',
            description:'Участок магистрального нефтепровода "Сургут-Полоцк" 76 км-137 км. Замена трубы на участке 87-100км. DN1200. Нефтеюганское УМН. Реконструкция',
            dateinto: '17-04-2024'
        },
        {
            id:7,
            name:'396',
            description:'РВСП 20000 м3 №4 НПС "Холмогоры". Ноябрьское УМН. Техническое перевооружение',
            dateinto: '24-04-2024'
        }
    ]
    const weldingCrews = [
        {
            id: 1,
            crew: 'Бр. Изомова С.Ш.',
            mans: [
                {
                    name: 'Мухамедов Икром Исламович',
                    develop: 'электросварщик ручной сварки 6 разряда',
                    tabelnum: '0001-02'
                },

                {
                    name: 'Изомов Фаррух Кахрамонович',
                    develop: 'электросварщик ручной сварки 5 разряда',
                    tabelnum: '0002-02'
                },

                {
                    name: 'Курбонов Илхомжон Журакулович',
                    develop: 'газорезчик 5 разряда',
                    tabelnum: '0003-02'
                },

                {
                    name: 'Изомов Сохиб Ширинович',
                    develop: 'электрогазосварщик 6 разряда',
                    tabelnum: '0004-02'
                },

                {
                    name: 'Аллазов Мурод Айваз Угли',
                    develop: 'электрогазосварщик 6 разряда',
                    tabelnum: '0005-02'
                },

                {
                    name: 'Ёдгоров Жафарбек Жалолиддин Угли',
                    develop: 'монтажник технологических трубопроводов 5 разряда',
                    tabelnum: '0006-02'
                }
            ],
            admission: [
                {
                    name: 'СУР-24АЦ-I-15438',
                    datein: '2023-09-05',
                    dateout: '2025-09-05'
                },

                {
                    name: 'СУР-24АЦ-I-16716',
                    datein: '2023-12-15',
                    dateout: '2025-12-15'
                }
            ],
            brand: 'Р-128',
            tabel: [
                {
                    year: '2024',
                    month: '0',
                    types:[
                        {
                            name:'Сварка 1',
                            days:{
                                d1:1,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },

                        },
                        {
                            name:'Сварка 2',
                            days:{
                                d1:2,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        },
                        {
                            name:'Сварка 3',
                            days:{
                                d1:3,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        }
                    ],
                    peoples:[
                        {
                            name:'Иванов',
                            days:{
                                d1:1,
                                d2:1,
                                d3:1,
                                d4:1,
                                d5:1,
                                d6:1,
                                d7:1,
                                d8:1,
                                d9:1,
                                d10:1,
                                d11:1,
                                d12:1,
                                d13:1,
                                d14:1,
                                d15:1,
                                d16:1,
                                d17:1,
                                d18:1,
                                d19:1,
                                d20:1,
                                d21:1,
                                d22:1,
                                d23:1,
                                d24:1,
                                d25:1,
                                d26:1,
                                d27:1,
                                d28:1,
                                d29:1,
                                d30:1,
                                d31:1
                            }
                        },
                        {
                            name:'Петров',
                            days:{
                                d1:2,
                                d2:2,
                                d3:2,
                                d4:2,
                                d5:2,
                                d6:2,
                                d7:2,
                                d8:2,
                                d9:2,
                                d10:2,
                                d11:2,
                                d12:2,
                                d13:2,
                                d14:2,
                                d15:2,
                                d16:2,
                                d17:2,
                                d18:2,
                                d19:2,
                                d20:2,
                                d21:2,
                                d22:2,
                                d23:2,
                                d24:2,
                                d25:2,
                                d26:2,
                                d27:2,
                                d28:2,
                                d29:2,
                                d30:2,
                                d31:2
                            }
                        },
                        {
                            name:'Сидоров',
                            days:{
                                d1:3,
                                d2:3,
                                d3:3,
                                d4:3,
                                d5:3,
                                d6:3,
                                d7:3,
                                d8:3,
                                d9:3,
                                d10:3,
                                d11:3,
                                d12:3,
                                d13:3,
                                d14:3,
                                d15:3,
                                d16:3,
                                d17:3,
                                d18:3,
                                d19:3,
                                d20:3,
                                d21:3,
                                d22:3,
                                d23:3,
                                d24:3,
                                d25:3,
                                d26:3,
                                d27:3,
                                d28:3,
                                d29:3,
                                d30:3,
                                d31:3
                            }
                        },
                    ]
                },
                {
                    year: '2024',
                    month: '1',
                    types:[
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:3,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },

                        },
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        },
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        }
                    ],
                    peoples:[
                        {
                            name:'Иванов',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Petrov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Sidorov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                    ]
                },
                {
                    year: '2024',
                    month: '2',
                    types:[
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },

                        },
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        },
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        }
                    ],
                    peoples:[
                        {
                            name:'Ivanov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Petrov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Сидоров',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                    ]
                }

            ]

        },

        {
            id: 2,
            crew: 'Бр. Олимова Р.К.',
            mans: [
                {
                    name: 'Олимов Алишер Расулович',
                    develop: 'электросварщик ручной сварки 6 разряда',
                    tabelnum: '0001-03'
                },

                {
                    name: 'Файзуллаев Собит Нарзиевич',
                    develop: 'электросварщик ручной сварки 6 разряда',
                    tabelnum: '0002-03'
                },

                {
                    name: 'Олимов Расул Каримович',
                    develop: 'газорезчик 6 разряда',
                    tabelnum: '0003-03'
                },

                {
                    name: 'Каримов Фаррух Зокирович',
                    develop: 'монтажник технологических трубопроводов 5 разряда',
                    tabelnum: '0004-03'
                },

                {
                    name: 'Асроров Азамат Ахрор Угли',
                    develop: 'монтажник технологических трубопроводов 5 разряда',
                    tabelnum: '0005-03'
                }
            ],
            admission: [],
            brand: 'Р-112',
            tabel: [
                {
                    year: '2024',
                    month: '0',
                    types:[
                        {
                            name:'Сварка 4',
                            days:{
                                d1:4,
                                d2:2,
                                d3:2,
                                d4:2,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },

                        },
                        {
                            name:'Сварка 5',
                            days:{
                                d1:3,
                                d2:3,
                                d3:2,
                                d4:3,
                                d5:4,
                                d6:5,
                                d7:3,
                                d8:5,
                                d9:8,
                                d10:5,
                                d11:4,
                                d12:3,
                                d13:2,
                                d14:2,
                                d15:7,
                                d16:5,
                                d17:4,
                                d18:4,
                                d19:4,
                                d20:6,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        },
                        {
                            name:'Сварка 6',
                            days:{
                                d1:6,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        }
                    ],
                    peoples:[
                        {
                            name:'Александров',
                            days:{
                                d1:4,
                                d2:4,
                                d3:4,
                                d4:4,
                                d5:4,
                                d6:4,
                                d7:4,
                                d8:4,
                                d9:4,
                                d10:4,
                                d11:4,
                                d12:4,
                                d13:4,
                                d14:4,
                                d15:4,
                                d16:4,
                                d17:4,
                                d18:4,
                                d19:4,
                                d20:4,
                                d21:4,
                                d22:4,
                                d23:4,
                                d24:4,
                                d25:4,
                                d26:4,
                                d27:4,
                                d28:4,
                                d29:4,
                                d30:4,
                                d31:4
                            }
                        },
                        {
                            name:'Денисов',
                            days:{
                                d1:5,
                                d2:5,
                                d3:5,
                                d4:5,
                                d5:5,
                                d6:5,
                                d7:5,
                                d8:5,
                                d9:5,
                                d10:5,
                                d11:5,
                                d12:5,
                                d13:5,
                                d14:5,
                                d15:5,
                                d16:5,
                                d17:5,
                                d18:5,
                                d19:5,
                                d20:5,
                                d21:5,
                                d22:5,
                                d23:5,
                                d24:5,
                                d25:5,
                                d26:5,
                                d27:5,
                                d28:5,
                                d29:5,
                                d30:5,
                                d31:5
                            }
                        },
                        {
                            name:'Андрюхин',
                            days:{
                                d1:5,
                                d2:5,
                                d3:5,
                                d4:5,
                                d5:5,
                                d6:5,
                                d7:5,
                                d8:5,
                                d9:5,
                                d10:5,
                                d11:5,
                                d12:5,
                                d13:5,
                                d14:5,
                                d15:5,
                                d16:5,
                                d17:5,
                                d18:5,
                                d19:5,
                                d20:5,
                                d21:5,
                                d22:5,
                                d23:5,
                                d24:5,
                                d25:5,
                                d26:5,
                                d27:5,
                                d28:5,
                                d29:5,
                                d30:5,
                                d31:5
                            }
                        },
                    ]
                },
                {
                    year: '2024',
                    month: '1',
                    types:[
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },

                        },
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        },
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        }
                    ],
                    peoples:[
                        {
                            name:'Ivanov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Petrov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Sidorov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                    ]
                },
                {
                    year: '2024',
                    month: '2',
                    types:[
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },

                        },
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        },
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        }
                    ],
                    peoples:[
                        {
                            name:'Ivanov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Petrov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Sidorov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                    ]
                }

            ]
        },

        {
            id: 3,
            crew: 'Бр. Меженский С.И.',
            mans: [
                {
                    name: 'Сабиров Денис Рашидович',
                    develop: 'электрогазосварщик 6 разряда',
                    tabelnum: '0001-04'
                },

                {
                    name: 'Березин Никита Алексеевич',
                    develop: 'монтажник технологических трубопроводов 5 разряда',
                    tabelnum: '0002-04'
                },

                {
                    name: 'Манапов Закир Магаметаминович',
                    develop: 'электрогазосварщик 6 разряда',
                    tabelnum: '0003-04'
                },

                {
                    name: 'Меженский Сергей Иванович',
                    develop: 'монтажник технологических трубопроводов 5 разряда',
                    tabelnum: '0004-04'
                },

                {
                    name: 'Блок Александ Александрович',
                    develop: 'монтажник технологических трубопроводов 5 разряда',
                    tabelnum: '0005-04'
                }
            ],
            admission: [
                {
                    name: 'СУР-24АЦ-I-15295',
                    datein: '2023-08-08',
                    dateout: '2025-08-08'
                },

                {
                    name: 'СУР-24АЦ-I-15292',
                    datein: '2023-08-08',
                    dateout: '2025-08-08'
                }
            ],
            brand: 'Р-147',
            tabel: [
                {
                    year: '2024',
                    month: '0',
                    types:[
                        {
                            name:'Сварка 7',
                            days:{
                                d1:7,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:5,
                                d13:4,
                                d14:5,
                                d15:7,
                                d16:3,
                                d17:0,
                                d18:78,
                                d19:55,
                                d20:5,
                                d21:4,
                                d22:44,
                                d23:6,
                                d24:0,
                                d25:4,
                                d26:11,
                                d27:7,
                                d28:56,
                                d29:0,
                                d30:5,
                                d31:0
                            },
                            plan:{
                                d1:9,
                                d2:8,
                                d3:7,
                                d4:7,
                                d5:6,
                                d6:5,
                                d7:4,
                                d8:4,
                                d9:2,
                                d10:3,
                                d11:1,
                                d12:75,
                                d13:4,
                                d14:45,
                                d15:5,
                                d16:5,
                                d17:5,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },

                        },
                        {
                            name:'Сварка 8',
                            days:{
                                d1:8,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        },
                        {
                            name:'Сварка 9',
                            days:{
                                d1:9,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        }
                    ],
                    peoples:[
                        {
                            name:'Лукинов',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Говорунов',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Рябчиков',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                    ]
                },
                {
                    year: '2024',
                    month: '1',
                    types:[
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },

                        },
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        },
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        }
                    ],
                    peoples:[
                        {
                            name:'Ivanov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Petrov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Sidorov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                    ]
                },
                {
                    year: '2024',
                    month: '2',
                    types:[
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },

                        },
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        },
                        {
                            name:'vid raboty #1',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                            plan:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            },
                        }
                    ],
                    peoples:[
                        {
                            name:'Ivanov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Petrov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                        {
                            name:'Sidorov',
                            days:{
                                d1:11,
                                d2:11,
                                d3:11,
                                d4:0,
                                d5:0,
                                d6:11,
                                d7:11,
                                d8:11,
                                d9:0,
                                d10:11,
                                d11:11,
                                d12:11,
                                d13:0,
                                d14:11,
                                d15:11,
                                d16:11,
                                d17:0,
                                d18:11,
                                d19:11,
                                d20:11,
                                d21:0,
                                d22:11,
                                d23:0,
                                d24:11,
                                d25:0,
                                d26:11,
                                d27:0,
                                d28:11,
                                d29:0,
                                d30:11,
                                d31:0
                            }
                        },
                    ]
                }

            ]
        }
    ]
    const btns_modules = [
        //табеля
        {
            name: 'tabel',
            title: 'Табеля',
            url: '/tabelportal',
            icon: 'fa-solid fa-table-list',
            toid: 0
        },
        // документооборот
        // {
        //     name: 'documents',
        //     title: 'Документо оборот',
        //     url: '/docpasslist',
        //     toid: 0
        // },
        // ТМЦ
        // {
        //     name: 'goods',
        //     title: 'ТМЦ',
        //     url: '/goods',
        //     toid: 0
        //
        // },
        // сварщики
        {
            name: 'welding',
            title: 'Сварщики',
            url: '/welwel',
            icon: 'fa-solid fa-wand-magic-sparkles',
            toid: 1
        },
        // персонал
        // {
        //     name: 'humanresource',
        //     title: 'Персонал',
        //     url: '/personal',
        //     toid: 0
        // }

    ]
    const table_tabel = [
        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'11',
            m2:'',
            m3:'11',
            m4:'11',
            m5:'11',
            m6:'11',
            m7:'',
            m8:'',
            m9:'',
            m10:'11',
            m11:'11',
            m12:'11',
            m13:'11',
            m14:'11',
            m15:'11',
            m16:'',
            m17:'',
            m18:'11',
            m19:'11',
            m20:'11',
            m21:'11',
            m22:'11',
            m23:'11',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'11',
            m30:'11',
            m31:'11',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Сидоров Сидр Сидорович',
            developer:'электрический электроник электричества 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'11',
            m2:'11',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'11',
            m10:'11',
            m11:'11',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        },

        {
            id:1,
            full_name:'Иванов Иван Иванович',
            developer:'Машинист электростанции передвижной 6 разряда',
            branch:'Отдел механизации',
            object_id:1,
            month:'январь',
            year:'2024',
            ktu:'1',
            marker:1,
            ras:'390',
            company_id:1,
            transport:'Электростанция передвижная',
            price:'3000',
            tn:'000-00456',
            m1:'',
            m2:'',
            m3:'',
            m4:'',
            m5:'',
            m6:'',
            m7:'',
            m8:'',
            m9:'',
            m10:'',
            m11:'',
            m12:'',
            m13:'',
            m14:'',
            m15:'',
            m16:'',
            m17:'',
            m18:'',
            m19:'',
            m20:'',
            m21:'',
            m22:'',
            m23:'',
            m24:'',
            m25:'',
            m26:'',
            m27:'',
            m28:'',
            m29:'',
            m30:'',
            m31:'',
            c1:'',
            c2:'',
            c3:'',
            c4:'',
            c5:'',
            c6:'',
            c7:'',
            c8:'',
            c9:'',
            c10:'',
            c11:'',
            c12:'',
            c13:'',
            c14:'',
            c15:'',
            c16:'',
            c17:'',
            c18:'',
            c19:'',
            c20:'',
            c21:'',
            c22:'',
            c23:'',
            c24:'',
            c25:'',
            c26:'',
            c27:'',
            c28:'',
            c29:'',
            c30:'',
            c31:'',
            dop1:'',
            dop2:'',
            dop3:'',
            dop4:'',
            dop5:'',
            dop6:'',
            dop7:'',
            dop8:'',
            dop9:'',
            dop10:'',
            dop11:'',
            dop12:'',
            dop13:'',
            dop14:'',
            dop15:'',
            dop16:'',
            dop17:'',
            dop18:'',
            dop19:'',
            dop20:'',
            dop21:'',
            dop22:'',
            dop23:'',
            dop24:'',
            dop25:'',
            dop26:'',
            dop27:'',
            dop28:'',
            dop29:'',
            dop30:'',
            dop31:''

        }

    ]
    const docs = [
        { id: 1, title: 'Договор аренды', category: 'Юридические документы', size: '1.2 MB', fileType: 'PDF', fileName: 'dogovor_arendy.pdf' },
        { id: 2, title: 'Техническое задание', category: 'Проектная документация', size: '3.5 MB', fileType: 'DOCX', fileName: 'technical_specification.docx' },
        { id: 3, title: 'Финансовый отчет', category: 'Финансовые документы', size: '750 KB', fileType: 'XLSX', fileName: 'financial_report.xlsx' },
        { id: 4, title: 'Спецификация товаров', category: 'Торговая документация', size: '2.1 MB', fileType: 'PDF', fileName: 'specification_goods.pdf' },
        { id: 5, title: 'План маркетинга', category: 'Маркетинговые документы', size: '4.8 MB', fileType: 'PPTX', fileName: 'marketing_plan.pptx' },
        { id: 6, title: 'Трудовой договор', category: 'Кадровые документы', size: '980 KB', fileType: 'DOCX', fileName: 'employment_contract.docx' },
        { id: 7, title: 'Счет-фактура', category: 'Финансовые документы', size: '1.6 MB', fileType: 'PDF', fileName: 'invoice.pdf' },
        { id: 8, title: 'Проектный план', category: 'Проектная документация', size: '2.9 MB', fileType: 'PPTX', fileName: 'project_plan.pptx' },
        { id: 9, title: 'Устав организации', category: 'Юридические документы', size: '1.1 MB', fileType: 'PDF', fileName: 'charter.pdf' },
        { id: 10, title: 'Спецификация программного обеспечения', category: 'IT-документация', size: '6.2 MB', fileType: 'DOCX', fileName: 'software_specification.docx' },
        { id: 11, title: 'Справка по здоровью', category: 'Медицинские документы', size: '500 KB', fileType: 'PDF', fileName: 'health_certificate.pdf' },
        { id: 12, title: 'Бухгалтерская отчетность', category: 'Финансовые документы', size: '2.5 MB', fileType: 'XLSX', fileName: 'accounting_reports.xlsx' },
        { id: 13, title: 'Технический паспорт оборудования', category: 'Техническая документация', size: '1.8 MB', fileType: 'PDF', fileName: 'equipment_passport.pdf' },
        { id: 14, title: 'Уведомление о начислении заработной платы', category: 'Кадровые документы', size: '750 KB', fileType: 'DOCX', fileName: 'payroll_notification.docx' },
        { id: 15, title: 'Протокол собрания акционеров', category: 'Юридические документы', size: '3.3 MB', fileType: 'PDF', fileName: 'shareholders_meeting_protocol.pdf' }
    ]
    const icons = {
        'dir':'fa-folder',
        'doc':'fa-file-word',
        'xls':'fa-file-excel',
        'docx':'fa-file-word',
        'wps':'fa-file-lines',
        'xlsx':'fa-file-excel',
        'csv':'fa-file-excel',
        'pdf':'fa-file-pdf',
        'rar':'fa-file-zipper',
        'zip':'fa-file-zipper',
        '7z':'fa-file-zipper',
        'gzip':'fa-file-zipper',
        'jpg':'fa-file-image',
        'png':'fa-file-image',
        'bmp':'fa-file-image',
        'gif':'fa-file-image',
        'tif':'fa-file-image',
        'txt':'fa-file-lines',
        'vsdx':'fa-file-lines',
        'vsd':'fa-file-lines',
        'gsf':'fa-file-lines',
        'xml':'fa-file-excel',
        'fb2':'fa-file-lines',
        default:'fa-file-lines'
    }
    const iconst = ['fa-solid','fa-regular']
    const COLORS = {
        '1': 'rgba(18,19,56,1)',
        '2': 'rgba(11,14,162,0.8)',
        '3': 'rgba(0,2,108,0.6)',
        '4': 'rgba(55,55,68,0.4)',
        '5': 'rgba(106,114,128,0.8)',
        '6': 'rgba(195,209,229,0.69)',
        '7': 'rgba(182,107,180,0.4)',
        '8': 'rgb(89,69,69)'
    }

    const structure = [
        { id: 1, label: 'Генеральный директор' },
        { id: 2, label: 'Исполнительный директор' },
        { id: 3, label: 'Главный инженер' },
        { id: 4, label: 'Заместитель главного инженера'},
        { id: 5, label: 'Заместитель генерального директора по производству' },
        { id: 6, label: 'Технический отдел'},
        { id: 7, label: 'Лаборатория неразрушающего контроля'},
        { id: 8, label: 'Служба охраны труда' },
        { id: 9, label: 'Отдел геодезии'},
        { id: 10, label: 'Отдел главного энергетика' },
        { id: 11, label: 'Служба главного сварщика' },
        { id: 12, label: 'Служба экологической безопасности' },
        { id: 13, label: 'Служба контроля качества' },
        { id: 14, label: 'Производственный участок №1' },
        { id: 15, label: 'Производственный участок №2' },
        { id: 16, label: 'Производственный участок №3' },
        { id: 17, label: 'Производственный участок №4' },
        { id: 18, label: 'Производственный участок №5' },
        { id: 19, label: 'Производственный участок №6' },
        { id: 20, label: 'Производственный участок №7' },
        { id: 21, label: 'Производственный участок №8' },
        { id: 22, label: 'Производственный участок №9' },
        { id: 23, label: 'Заместитель генерального директора по общим вопросам'},
        { id: 24, label: 'Центральная база' },
        { id: 25, label: 'Отдел Экономической безопасности'},
        { id: 26, label: 'Отдел материально-технического снабжения'},
        { id: 27, label: 'Отдел кадров'},
        { id: 28, label: 'Отдел эксплуатации и технического контроля'},
        { id: 29, label: 'Склад запчастей'},
        { id: 30, label: 'Склад ГСМ'},
        { id: 31, label: 'Отдел социального развития'},
        { id: 32, label: 'Юридическая служба'},
        { id: 33, label: 'Центральный склад'},
        { id: 34, label: 'Главный механик'},
        { id: 35, label: 'Отдел главного механика'},
        { id: 36, label: 'Автомобильная колонна №1'},
        { id: 37, label: 'Автомобильная колонна №2'},
        { id: 38, label: 'Автомобильная колонна №3'},
        { id: 39, label: 'Заместитель генерального директора по экономике'},
        { id: 40, label: 'Планово-производственный отдел'},
        { id: 41, label: 'Отдел подготовки производства'},
        { id: 42, label: 'Экономическая служба'},
        { id: 43, label: 'ОУИД'},
        { id: 44, label: 'Главный бухгалтер'},
        { id: 45, label: 'Бухгалтерия'},
        { id: 46, label: 'Служба делопроизводства'},
        { id: 47, label: 'Отдел продаж'}
    ]

    const getMonthName = (monthIndex) => {
        const monthNames = [
            "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
            "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
        ];
        return monthNames[monthIndex];
    }
    const optionsYear = [
        { value: '0', label: '2022' },
        { value: '1', label: '2023' },
        { value: '2', label: '2024' }
    ]
    const optionsYear2 = [
        { value: 2023, label: '2023' },
        { value: 2024, label: '2024' },
        { value: 2025, label: '2025' }
    ]
    const optionsYear3 = [
        { value: 2023, label: '2023' },
        { value: 2024, label: '2024' },
        { value: 2025, label: '2025' }
    ]
    const optionsMonth3 = [
        { value: 0, label: 'январь' },
        { value: 1, label: 'февраль' },
        { value: 2, label: 'март' },
        { value: 3, label: 'апрель' },
        { value: 4, label: 'май' },
        { value: 5, label: 'июнь' },
        { value: 6, label: 'июль' },
        { value: 7, label: 'август' },
        { value: 8, label: 'сентябрь' },
        { value: 9, label: 'октябрь' },
        { value: 10, label: 'ноябрь' },
        { value: 11, label: 'декабрь' }
    ]
    const optionsMonth = [
        { value: '0', label: 'январь' },
        { value: '1', label: 'февраль' },
        { value: '2', label: 'март' },
        { value: '3', label: 'апрель' },
        { value: '4', label: 'май' },
        { value: '5', label: 'июнь' },
        { value: '6', label: 'июль' },
        { value: '7', label: 'август' },
        { value: '8', label: 'сентябрь' },
        { value: '9', label: 'октябрь' },
        { value: '10', label: 'ноябрь' },
        { value: '11', label: 'декабрь' }
    ]

    const [selectedMenu,setSelectedMenu] = useState(-1)

    const sizesWear = [{value:46,label:46},{value:47,label:47},{value:48,label:48},{value:50,label:50},{value:51,label:51},{value:52,label:52},{value:53,label:53},{value:54,label:54},{value:55,label:55},{value:56,label:56}]
    const sizesShoes = [{value:37,label:37},{value:38,label:38},{value:39,label:39},{value:40,label:40},{value:41,label:41},{value:42,label:42},{value:43,label:43},{value:44,label:44},{value:45,label:45},{value:46,label:46},{value:47,label:47}]

    const emojis = ['😀','😊','😂','😢','😎','😍','🤔','🙄','😇','😱','❤️','👍','👊','🤟','🙏','💪'];
    const hourlyLabels = ['0:00','1:00','2:00','3:00','4:00','5:00','6:00','7:00','8:00','9:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','00:00'];
    const weeklyLabels = ['понедельник','вторник','среда','четверг','пятница','суббота','воскресенье'];
    const monthlyLabels = ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];
    const fileIcons = {
        jpg: '🖼️',
        png: '🖼️',
        gif: '🎞️',
        pdf: '📄',
        doc: '📝',
        docx: '📝',
        mp3: '🎵',
        mp4: '🎥',
        zip: '🗜️',
        default: '📄',
    }
    const stickers = ['тортик.png','мальвина.png','bobafet.png','мышки.png','donat.png','shary.png','леденец.png','tort.png','котик.png','kovsh.png','гном.png','Мандаринки.png']
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return (
        <DataContext.Provider value={{
            fileIcons,
            optionsMonth3,
            optionsYear3,
            urlRegex,
            optionsYear2,
            stickers,
            weeklyLabels,
            monthlyLabels,
            hourlyLabels,
            emojis,
            sizesWear,
            sizesShoes,
            optionsYear,
            structure,
            selectedMenu,setSelectedMenu,
            COLORS,
            getMonthName,
            optionsMonth,
            mass_blocks_admin,
            objs,
            mass_create_bridge,
            menu_mass_bridge,
            mass_create,
            menu_mass,
            wrap_buttons,
            task1,
            attach1,
            performers,
            results,
            dwm2,
            dwm1,
            mass_blocks,
            titlegraph1,
            titlegraph2,
            graphmass1,
            graphmass2,
            calendarmass,
            works1mass,
            works2mass,
            works3mass,
            list_titletasks,
            weldingCrews,
            my_objs,
            btns_modules,
            table_tabel,
            icons,
            iconst
        }}>
            {children}
            <ToastContainer />
        </DataContext.Provider>
    );
};