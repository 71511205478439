import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite"
import styles from './Cms.module.scss'
import LoadingSpinner from "../../components/loading/LoadingSpinner"
import MessagesService from "../../services/MessagesService";
import {Context} from "../../index";
import UserService from "../../services/UserService";
import TelegramService from "../../services/TelegramService";

function Testing(){
    const {store} = useContext(Context)
    const [loading,setLoading] = useState(false)

    const sendMessage = async () => {
        try {
            setLoading(true)
            console.log('start sending...')
            const {data} = await MessagesService.sendNewMessage(308, 'Новое заявление на матерриальную помощь. Для просмотра перейдите в меню "Заявления -> Список заявлений"') // 308 - служба поддержки
            console.log(data)
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const RemoveMonthT13 = async () => {
        try {
            setLoading(true)
            const {data} = await UserService.RemoveMonthT13('сентябрь',2024)
            console.log(data)
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const ListenAgainist = async () => {
        try {
            setLoading(true)
            const {data} = await UserService.ListenAgainist()
            console.log(data)
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const seeTelegramMe = async () => {
        try {
            setLoading(true)
            const {data} = await TelegramService.getMe()
            console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const sendTelegramMessage = async () => {

    }
    return (
        <>
            <div className={styles.container}>
                <h1 style={{marginBottom:'10px'}}>Testing</h1>
                <div className={styles.testing}>
                    <div className={styles.buttons}>
                        <div /*onClick={sendMessage}*/ className={styles.button}>send message</div>
                        <div /*onClick={RemoveMonthT13}*/ className={styles.button}>Remove Month 2024 from t13</div>
                        <div /*onClick={ListenAgainist}*/ className={styles.button}>See newly established</div>
                    </div>

                    <div className={styles.buttons}>
                        <div onClick={seeTelegramMe} className={styles.button}>seeMe telegram bot</div>
                        <div onClick={sendTelegramMessage} className={styles.button}>send test message to telegram</div>
                    </div>
                </div>
            </div>
            {loading ? (<LoadingSpinner/>) : null}
        </>
    )
}
export default observer(Testing)