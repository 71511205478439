import React, {useContext, useEffect, useState} from "react"
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"

import './assets/styles/styles.scss'
import './assets/styles/media1550.scss'
import '@fortawesome/fontawesome-free/css/all.css'

import AuthPage from "./pages/AuthPage"
import { DataProvider } from './context/DataContext'
import FileManagerPage from "./pages/documents/fileManagerPage"
import {Context} from "./index"
import {observer} from "mobx-react-lite"
import NewstartPage from "./pages/NewstartPage"
import LkNewPortal from "./pages/lknewportal/LkNewPortal"
import NewPayList from "./pages/NewPayList"
import NewPhoneBook from "./pages/NewPhoneBook"
import Createnews from "./pages/news/Createnews"
import Viewnews from "./pages/news/Viewnews"
import AllListNews from "./pages/news/AllListNews"
import Listnews from "./pages/news/Listnews"
import PollsPage from "./pages/survey/PollsPage"
import PollsCms from "./pages/survey/PollsCms"
import HallEdit from "./pages/hallofframe/HallEdit"
import MainCms from "./pages/news/MainCms"
import TaskRouter from "./pages/tasks/TaskRouter"
import StructureRouter from "./pages/structure/StructureRouter"
import NewPageObjects from "./pages/objects/NewPageObjects"
import Statements from "./pages/documents/Statements"
import {EconomistMenu} from "./pages/economist/EconomistMenu"
import OgmPage from "./pages/econom/OgmPriceRouter"
import {ItogTabel} from "./pages/tabel/ItogTabel"
import {AllTabels} from "./pages/tabel/AllTabels"
import {HrMenu} from "./pages/hr/HrMenu"
import HrRouter from "./pages/hr/HrRouter"
import {Welcomepage} from "./pages/welcomepage/Welcomepage"
import {Fz152} from "./pages/welcomepage/Fz152"
import {MakeLogin} from "./pages/welcomepage/MakeLogin"
import WeldingRouter from "./pages/welding/WeldingRouter"
import EditorRouter from "./pages/editor/EditorRouter"
import SurveyPage from "./pages/survey/SurveyPage"
import ByePage from "./pages/survey/ByePage"
import Page404 from "./pages/survey/Page404"
import StatementsRouter from "./pages/statements/StatementsRouter"
import HrmPage from "./pages/survey/HrmPage"
import AnalyticsRouter from "./pages/analytics/AnalyticsRouter"
import Notifications from "./pages/message/Notifications"
import FixersRouter from "./pages/fixers/fixersRouter"
import OpenAiPage from "./pages/ai/OpenAiPage"
import Messager from "./pages/message/MessagerLanding"
import ContestStat from "./pages/kidscontest/ContestStat"
import GalaryList from "./pages/galary/GalaryList"
import EditPage from "./pages/galary/EditPage"
import CmsRouter from "./pages/1c/CmsRouter"
import AboutUsLanding from "./pages/about/AboutUsLanding"
import MedicialRouter from "./pages/meditial/MedicialRouter"
import {Repass} from "./pages/welcomepage/Repass"
import {NaturePower} from "./pages/naturepower/NaturePower"
import ObjectsRouter from "./pages/objects/ObjectsRouter"
import MainTasks from "./pages/tasks/MainTasks"
import ThisTask from "./pages/tasks/ThisTask"
import ProjectsPage from "./pages/projects/ProjectsPage";
import ProjectsRouter from "./pages/projects/ProjectsRouter";


function App() {
    const {store} = useContext(Context)

    const U = store.user.unit
    const S = store.pointsv
    const A = store.user.account
    const E = store.user.editcom

    useEffect(() => {
        if(localStorage.getItem('token')){
            store.checkAuth()
            store.readUserDay()
        }
    },[])
    if(store.isLoading){
        return <div>Загрузка...</div>
    }
    if(store.isCreated) return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/*" element={<Welcomepage />} />
                    <Route path="/fz152" element={<Fz152 />} />
                </Routes>
            </div>
        </Router>
    )
    if(store.isTn) return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/*" element={<MakeLogin />} />
                </Routes>
            </div>
        </Router>
    )
    if(store.t13.onboard){
        const dateOnboard = new Date(store.t13.onboard.split('.').reverse().join('-'));
        const dateSurvei = new Date('2024-04-25')
        if(dateSurvei<=dateOnboard && !store.isSurvey) return (
            <Router>
                <div className="App">
                    <Routes>
                        <Route path='*' element={<SurveyPage flag={true} onLand={true} id={10}/>} />
                    </Routes>
                </div>
            </Router>
        )
    }
    if(!store.isAuth) return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path='*' element={<AuthPage/>} />
                    <Route path="/repass" element={<Repass />} />
                </Routes>
            </div>
        </Router>
    )
    if(store.t13.term || store.onfired) return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path='*' element={<ByePage/>} />
                </Routes>
            </div>
        </Router>
    )
    if(!store.t13.tn) return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path='*' element={<Page404/>} />
                </Routes>
            </div>
        </Router>
    )
    if(A === 'ok' && !store.hrmcheck) return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path='*' element={<HrmPage/>} />
                </Routes>
            </div>
        </Router>
    )
    return (
        <DataProvider>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path='/*' element={<NewstartPage/>}/>
                        <Route path="/" element={<NewstartPage/>} />
                        {/*<Route path='/tasks/list' element={<TaskRouter page={3}/>}/>*/}
                        <Route path='/structure' element={<StructureRouter/>}/>
                        <Route path='/tasks' element={<TaskRouter/>}/>
                        <Route path="/new_lk" element={<LkNewPortal/>} />
                        <Route path='/statements' element={<Statements/>}/>
                        <Route path="/newpaylist" element={<NewPayList/>} />
                        <Route path="/polls" element={<PollsPage/>} />
                        <Route path="/galary" element={<GalaryList/>} />
                        <Route path="/documents" element={<FileManagerPage/>} />
                        <Route path="/newphonebook" element={<NewPhoneBook />} />
                        <Route path="/viewpost" element={<Viewnews />} />
                        <Route path="/alllistnews" element={<AllListNews />} />
                        <Route path="/messages" element={<Messager />} />
                        <Route path="/about" element={<AboutUsLanding />} />
                        <Route path="/createsocial" element={<EditorRouter page={4} />} />
                        <Route path="/naturepower" element={<NaturePower />} />

                        {A === 'superadmin' || U === 7 ? <Route path='/srto' element={<OgmPage page={8}/>}/> : null }
                        {A === 'superadmin' || U === 7 ? <Route path='/loadpayslip' element={<OgmPage page={6}/>}/> : null }
                        {A === 'superadmin' || U === 7 ? <Route path='/ktulist' element={<OgmPage page={5}/>}/> : null }
                        {A === 'superadmin' || U === 7 ? <Route path='/kturead' element={<OgmPage page={7}/>}/> : null }
                        {A === 'superadmin' || U === 7 ? <Route path='/ktu' element={<OgmPage page={4}/>}/> : null }
                        {A === 'superadmin' || U === 7 ? <Route path='/t13' element={<OgmPage page={3}/>}/> : null }
                        {A === 'superadmin' || U === 7 || U === 5 ? <Route path='/workprice' element={<OgmPage page={2}/>}/> : null }

                        {A === 'superadmin' || U === 7 ? <Route path='/ogmprice' element={<OgmPage page={1}/>}/> : null }
                        {A === 'superadmin' || U === 7 ? <Route path='/ogmpricemonth' element={<OgmPage page={9}/>}/> : null }

                        {A === 'superadmin' || U === 7 ? <Route path='/gpkh' element={<HrRouter page={1}/>}/> : null }
                        {A === 'superadmin' || U === 7 ? <Route path="/economist" element={<EconomistMenu/>} /> : null}
                        {A === 'superadmin' || U === 7 ? <Route path="/hr" element={<HrMenu/>} /> : null }
                        {A === 'superadmin' || U === 7 ? <Route path="/alltabels" element={<AllTabels />} /> : null}

                        {A === 'superadmin' || U === 99 ? <Route path='/settingmain' element={<MainCms/>}/> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/editgalary" element={<EditPage/>} /> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/polls/cms" element={<PollsCms/>} /> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/createnews" element={<Createnews/>} /> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/settingnews" element={<Listnews/>} /> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/halledit" element={<HallEdit />} /> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/conteststat" element={<ContestStat />} /> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/editor" element={<EditorRouter page={1} />} /> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/peoplesstat" element={<EditorRouter page={2} />} /> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/sociality" element={<EditorRouter page={3} />} /> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/cmsmed" element={<EditorRouter page={9} />} /> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/userbranchs" element={<EditorRouter page={5} />} /> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/cmsstructure" element={<EditorRouter page={6} />} /> : null }
                        {A === 'superadmin' || U === 99 ? <Route path="/cmsnotifications" element={<EditorRouter page={7} />} /> : null }

                        {A === 'superadmin' || U === 5 ? <Route path="/weldingmenu" element={<WeldingRouter page={1} />} /> : null }
                        {A === 'superadmin' || U === 5 ? <Route path="/weldingsett" element={<WeldingRouter page={2} />} /> : null }
                        {A === 'superadmin' || U === 5 ? <Route path="/weldingloadtypes" element={<WeldingRouter page={3} />} /> : null }

                        {A === 'superadmin' || E === 'projects' ? <Route path="/common" element={<ProjectsRouter page={1} />} /> : null }

                        {A === 'superadmin' || U === 5 || U === 7 || U === 1 || !!S ? <Route path="/thisobjsportal" element={<ObjectsRouter page={1} />} /> : null }
                        {A === 'superadmin' || U === 5 || U === 7 || U === 1 ? <Route path="/tabelportal" element={<ObjectsRouter page={2}  />} /> : null }
                        {A === 'superadmin' || U === 5 || U === 7 || U === 1 ? <Route path="/thistabelportal" element={<ObjectsRouter page={3} />} /> : null }
                        {A === 'superadmin' || U === 5 || U === 7 || U === 1 ? <Route path="/svodka" element={<ObjectsRouter page={4} />} /> : null }

                        {A === 'superadmin' || U === 5 || U === 7 || !!S ? <Route path="/welwel" element={<ObjectsRouter page={11} />} /> : null }
                        {A === 'superadmin' || U === 5 || U === 7 || !!S ? <Route path="/tab-welding" element={<ObjectsRouter page={12} path={'welding'}/>} /> : null }
                        {A === 'superadmin' || U === 5 || U === 7 || !!S ? <Route path="/welcontroll" element={<ObjectsRouter page={13} />} /> : null }

                        {A === 'superadmin' || U === 5 || U === 7 || U === 1 || !!S ? <Route path="/objectsportal" element={<NewPageObjects />} /> : null }

                        {A === 'superadmin' || A === 'reader' || U === 99 || U === 7 ? <Route path="/statementsmenu" element={<StatementsRouter page={1} />} /> : null }
                        {A === 'superadmin' || A === 'reader' || U === 99 || U === 7 ? <Route path="/statementslist" element={<StatementsRouter page={2} />} /> : null }
                        {A === 'superadmin' || A === 'reader' || U === 99 || U === 7 ? <Route path="/protocol" element={<StatementsRouter page={3} />} /> : null }

                        {A === 'superadmin' || A === 'reader' || U === 3 || U === 7 ? <Route path="/itogtabel" element={<ItogTabel />} /> : null}

                        {A === 'superadmin' || A === 'analyst' ? <Route path="/analytics" element={<AnalyticsRouter page={1} />} /> : null }
                        {A === 'superadmin' || A === 'analyst' ? <Route path="/hrmanalytics" element={<AnalyticsRouter page={2} />} /> : null }
                        {A === 'superadmin' || A === 'analyst' ? <Route path="/byeanalytics" element={<AnalyticsRouter page={3} />} /> : null }
                        {A === 'superadmin' || A === 'analyst' ? <Route path="/dashboard" element={<AnalyticsRouter page={4} />} /> : null }
                        {A === 'superadmin' || A === 'analyst' ? <Route path="/history" element={<AnalyticsRouter page={5} />} /> : null }
                        {A === 'superadmin' || A === 'analyst' ? <Route path="/analyticscms" element={<AnalyticsRouter page={6} />} /> : null }
                        {A === 'superadmin' || A === 'analyst' ? <Route path="/analyticsopenai" element={<AnalyticsRouter page={7} />} /> : null }
                        {A === 'superadmin' || A === 'analyst' ? <Route path="/historywelding" element={<AnalyticsRouter page={8} />} /> : null }

                        {A === 'superadmin' ? <Route path="/notifications" element={<Notifications />} /> : null }
                        {A === 'superadmin' ? <Route path="/cms" element={<CmsRouter page={1} />} /> : null }
                        {A === 'superadmin' ? <Route path="/import1c" element={<CmsRouter page={2} />} /> : null }
                        {A === 'superadmin' ? <Route path="/export1c" element={<CmsRouter page={3} />} /> : null }
                        {A === 'superadmin' ? <Route path="/testing" element={<CmsRouter page={4} />} /> : null }

                        {A === 'superadmin' || A === 'fixers' ? <Route path="/fixers" element={<FixersRouter page={1} />} /> : null }
                        {A === 'superadmin' || A === 'fixers' ? <Route path="/registration" element={<FixersRouter page={2} />} /> : null }
                        {A === 'superadmin' || A === 'fixers' ? <Route path="/changeava" element={<FixersRouter page={3} />} /> : null }
                        {A === 'superadmin' || A === 'fixers' ? <Route path="/pointsv" element={<FixersRouter page={4} />} /> : null }
                        {A === 'superadmin' || A === 'fixers' ? <Route path="/openai" element={<OpenAiPage />} /> : null }
                        {A === 'superadmin' || A === 'fixers' ? <Route path="/repass" element={<FixersRouter page={5} />} /> : null }
                        {A === 'superadmin' || A === 'fixers' ? <Route path="/medicial" element={<MedicialRouter page={1} />} /> : null }
                        {A === 'superadmin' || A === 'fixers' ? <Route path="/medosmotry" element={<MedicialRouter page={2} />} /> : null }
                        {A === 'superadmin' || A === 'fixers' ? <Route path="/prikazmedosmotry" element={<MedicialRouter page={3} />} /> : null }

                        {/*<Route path="/resultscontest" element={<EditorRouter page={8} />} />*/}
                        {/*<Route path="/hrm" element={<HrmPage />} />*/}
                        {/*<Route path="/listfastbtns" element={<ListFastBtns />} />*/}
                        {/*<Route path="/conference" element={<ChatPage />} />*/}
                        {/*<Route path="/odata" element={<ODataPage />} />*/}
                        {/*<Route path="/peoples" element={<PeoplesPage />} />*/}
                        {/*<Route path="/selected_news/:id" element={<SelNewsPage/>} />*/}
                        {/*<Route path="/lk" element={<MainPage/>} />*/}
                        {/*<Route path="/testtaskpage/:params" element={<TestTaskPage />} />*/}
                        {/*<Route path="/lk" element={<LkPage/>} />*/}
                        {/*<Route path="/createtask" element={<CreateTaskPage />} />*/}
                        {/*<Route path="/document" element={<DocumentPage/>} />*/}
                        {/*<Route path="/phonebook" element={<PhonebookPage/>} />*/}
                        {/*<Route path="/settings" element={<SettingsPage/>} />*/}
                        {/*<Route path="/olddocuments" element={<DocumentsPage/>} />*/}
                        {/*<Route path='/main' element={<MainPage/>} />*/}
                        {/*<Route path="/load-contest" element={<LoadPage/>} />*/}
                        {/*<Route path="/kids-contest" element={<ContestPage/>} />*/}
                        {/*<Route path="/7897564" element={<NewsPage/>} />*/}
                        <Route path="/maintasks" element={<MainTasks/>} />
                        <Route path="/thistask" element={<ThisTask/>} />
                    </Routes>
                </div>
            </Router>
        </DataProvider>
    )
}

export default observer(App)
