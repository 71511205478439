import $api from "../http"

export default class TelegramService{
    static async getMe(){
        return $api.get(`/telegram/getme`)
    }
    static async LinkToBot(){
        return $api.get(`/telegram/link`)
    }

}
