import $api from "../http"

export default class ProjectsService{
    static async getProjectsStructure(){
        return $api.get('/common/getstructure')
    }
    static async downloadFile(name,path){
        return $api.post('/common/download',{name,path},{responseType:'blob'})
    }

}
