import React, {useCallback, useContext, useEffect, useState} from "react";
import {Link,useLocation} from 'react-router-dom';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {useMessage} from "../hooks/message.hook";
import {ToastContainer} from "react-toastify";

function AuthPage(){
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [empty,setEmpty] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)

    const {store} = useContext(Context)
    const message = useMessage()

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault()
        const promise = await store.login(login.toLowerCase().trim(),password)
        if(promise?.response?.data?.message){
            setEmpty(true)
            message(promise.response.data.message)
        }else{
            setEmpty(false)
        }
    }, [store, login, password, setEmpty, message])

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible)
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault()
                handleSubmit(event).then(() => {
                    console.log('enter')
                })
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleSubmit])

    return (
            <div className='auth'>
                <div className='auth_back'>
                    <div className='auth_form'>
                        <div className='logo'></div>
                        <div className='inauth'>
                            <input className={`${empty && 'red-auth-border'}`} type="text" placeholder='Имя пользователя:' value={login} onChange={e => setLogin(e.target.value)} autoComplete="off"/>

                            <div className={`password-input`}>
                                <input className={`${empty && 'red-auth-border'}`} type={passwordVisible ? 'text' : 'password'} placeholder='Пароль' value={password} onChange={e => setPassword(e.target.value)} autoComplete="off" />
                                <i onClick={togglePasswordVisibility} className={`eye ${!passwordVisible ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'}`}></i>
                            </div>

                            <div className='button' onClick={(e) => handleSubmit(e)}>Войти</div>
                            <Link to={'/repass'} className="first-link">Забыли пароль?</Link>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
    )
}
export default observer(AuthPage)