import React, {useContext, useEffect, useRef, useState} from "react"
import {observer} from "mobx-react-lite"
import s from "./ProjectsPage.module.scss"
import {Context} from "../../index"
import LoadingSpinner from "../../components/loading/LoadingSpinner"
import ProjectsService from "../../services/ProjectsService"
import truncateTextFromStart from "../../components/functions/truncateTextFromStart";
import FilesService from "../../services/FilesService";
import {DataContext} from "../../context/DataContext";

function ProjectsPage(){
    const {store} = useContext(Context)
    const {icons} = useContext(DataContext)
    const elementRef = useRef(null)
    const [width, setWidth] = useState(0)

    const [loading,setLoading] = useState(false)
    const [path,setPath] = useState([])
    const [structure,setStructure] = useState({})
    const [currentStructure,setCurrentStructure] = useState({})
    const [selectedFile,setSelectedFile] = useState('')
    const [isOpen,setIsOpen] = useState(false)
    const loadingHandler = async () => {
        try {
            setLoading(true)
            const {data} = await ProjectsService.getProjectsStructure()
            setStructure(JSON.parse(JSON.stringify(data)))
            setCurrentStructure(JSON.parse(JSON.stringify(data)))
            console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const openImgInNewWindow = () => {
        const encodedPath = path.map(encodeURIComponent).join("/")
        const encodedFile = encodeURIComponent(selectedFile)
        const fullUrl = `https://srsuportal.ru/projects/${encodedPath}/${encodedFile}`
        window.open(fullUrl, "_blank")
    }
    const openDocInNewWindow = () => {
        const encodedPath = path.map(encodeURIComponent).join("/")
        const encodedFile = encodeURIComponent(selectedFile)
        const fullUrl = `https://srsuportal.ru/projects/${encodedPath}/${encodedFile}`

        const encodedUrl = `https://docs.google.com/viewer?url=${fullUrl}`
        window.open(encodedUrl, "_blank")
    }
    const openFileHandler = () => {
        if(canIOpen(selectedFile) === 1){
            openImgInNewWindow()
        }else if (canIOpen(selectedFile) === 2){
            openDocInNewWindow()
        }
    }
    const downloadHandler = async () => {
        try {
            setLoading(true)
             const response = await ProjectsService.downloadFile(selectedFile,path)
             if(response.status === 200) {
                 const url = window.URL.createObjectURL(new Blob([response.data]))
                 const link = document.createElement('a')
                 link.href = url
                 link.setAttribute('download', selectedFile)
                 document.body.appendChild(link)
                 link.click()
                 window.URL.revokeObjectURL(url)
                 setLoading(false)
            }
        } catch (e) {
            console.log(e)
        }
    }

    function getFileIcon(filename) {
        const extension = filename.split('.').pop().toLowerCase()
        return icons[extension] || icons.default
    }
    const canIOpen = (name) => {
        const extension = name.split('.').pop().toLowerCase()
        if (['jpg', 'png', 'bmp','pdf', 'jpeg'].includes(extension) ) {
            return 1
        } else if (['doc', 'docx', 'xls', 'xlsx'].includes(extension)) {
            return 2
        } else {
            return 0
        }
    }

    const handleFileClick = (key) => {
        setIsOpen(!!canIOpen(key))
        setSelectedFile(key)
    }
    const handleFileDoubleClick = async (key) => {
        try {
            openFileHandler()
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const goHome = () => {
        setPath([])
        setSelectedFile(null)
        setIsOpen(false)
        setCurrentStructure(structure)
    }
    const goBack = () => {
        if (path.length === 0) return
        const newPath = path.slice(0, -1)
        let tempStructure = structure

        newPath.forEach((folder) => {
            tempStructure = tempStructure[folder]
        })

        setPath(newPath)
        setIsOpen(false)
        setSelectedFile(null)
        setCurrentStructure(tempStructure)
    }

    const handleFolderClick = (folderName) => {
        const newPath = [...path, folderName]
        setPath(newPath)
        setCurrentStructure(currentStructure[folderName])
    }
    useEffect(()=>{
        loadingHandler()

        const element = elementRef.current
        if (!element) return
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setWidth(entry.contentRect.width)
            }
        })

        resizeObserver.observe(element)

        return () => {
            resizeObserver.unobserve(element)
            resizeObserver.disconnect()
        }
    }, [])

    return (
        <div className={s.container}>
            <div className={s.buttons}>
                {selectedFile ? <div onClick={downloadHandler} className={`${s.button} ${s.grey}`}><i className="fa-solid fa-upload"></i>Скачать</div> : null}
                {selectedFile && isOpen ? <div onClick={openFileHandler} className={s.button}><i className="fa-solid fa-folder-plus"></i>Открыть</div> : null}
            </div>
            <div ref={elementRef} className={s.filesheader}>
                <span  className={s.gohome}>
                    <i onClick={() => goHome()} className="fa-solid fa-house"></i>
                    {path.length ? <div className={s.path}> > {truncateTextFromStart(path.join(` > `) || '',width/8)}</div> : null}
                </span>
            </div>
            <div className={s.explorer}>
                {path.length ?
                    <div onClick={goBack} className={s.back}>
                        <i className="fa-regular fa-circle-left"></i>
                        <p>Назад</p>
                    </div>
                    : null }
                {Object.keys(currentStructure).map((key) => {
                    const item = currentStructure[key]
                    const isFolder = typeof item === 'object' && !item.size
                    return (
                        <div className={s.folder}
                            key={key}
                            onClick={isFolder ? () => handleFolderClick(key) : null}
                            style={{
                                cursor: isFolder ? 'pointer' : 'default',
                                fontWeight: isFolder ? 'bold' : 'normal',
                            }}
                        >
                            {isFolder ? <> <i className={`fa-regular fa-folder`} /> {key} </> :
                                <div onClick={() => handleFileClick(key)}
                                     onDoubleClick={() => handleFileDoubleClick(key)}
                                     className={`${s.file} ${selectedFile === key && s.selected}`}>
                                     <i className={`fa-regular ${getFileIcon(key)}`} /> {key}
                                </div>} {/*(${item.size} bytes)*/}
                        </div>
                    )
                })}
            </div>
            {loading ? (<LoadingSpinner/>) : null}
        </div>
    )
}

export default observer(ProjectsPage)